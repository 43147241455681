<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - July 08, 2022
 -->

<template>
  <!-- Note about width calculation, (50%-half width of prop notification+ half width of the sidebar) -->
  <div
    class="fixed top-2 z-40 mx-auto max-h-32 w-fit overflow-hidden rounded-lg bg-white
    px-3 py-2 shadow-xl shadow-gray-400"
    :class="{
      'left-[calc(50%-84px)]': authStore.user,
      'inset-x-0': !authStore.user,
    }"
    :style="{ maxWidth }"
  >
    <div class="flex min-h-[60px] w-96 gap-1">
      <!-- Side color -->
      <div class="flex">
        <div
          class="max-h-28 w-2 rounded-full"
          :class="{
            'bg-amber-500': notificationType === NOTIFICATION.INFO,
            'bg-emerald-500': notificationType === NOTIFICATION.SUCCESS,
            'bg-red-600': notificationType === NOTIFICATION.ERROR,
          }"
        />
      </div>
      <!-- Icon -->
      <span class="flex max-h-28 items-center">
        <PhInfo
          v-if="notificationType === NOTIFICATION.INFO"
          class="h-8 w-8 text-amber-500"
        />
        <CheckCircleIcon
          v-else-if="notificationType === NOTIFICATION.SUCCESS"
          class="h-8 w-8 text-emerald-500"
        />
        <PhWarning
          v-else-if="notificationType === NOTIFICATION.ERROR"
          class="h-8 w-8 text-red-600"
        />
      </span>
      <!-- Content -->
      <div class="flex grow flex-col justify-center">
        <!-- Top element -->
        <div class="flex justify-between">
          <div class="flex grow justify-center">
            {{ notification }}
          </div>

          <button
            type="button"
            class="flex max-h-28 items-center rounded-lg text-gray-400"
            aria-label="Close"
            @click="$emit('close')"
          >
            <XMarkIcon
              class="h-6 w-6"
            />
          </button>
        </div>

        <!-- Bottom content element, download link for now -->
        <div class="flex w-full justify-end">
          <a
            v-if="anchorText && anchorLink"
            class="w-fit rounded bg-[#00A1E0] px-4 py-1 text-white shadow-lg shadow-blue-300
          duration-300 hover:bg-[#00A1E0]/80 hover:shadow-md hover:shadow-blue-300 hover:transition-all"
            :href="anchorLink"
          >
            {{ anchorText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import {
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  PhInfo,
  PhWarning,
} from '@phosphor-icons/vue';
import {
  NOTIFICATION,
} from '@/bus';
import {
  useStore,
} from '@/store';

const authStore = useStore.auth;

defineEmits<{
  (e: 'close'): void;
}>();

defineProps({
  notification: {
    type: String,
    required: true,
  },
  notificationType: {
    type: String,
    required: true,
  },
  anchorText: {
    type: String,
    default: '',
  },
  anchorLink: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: String,
    default: '',
  },
});

</script>
