/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import axios from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  CONSTANTS,
  abbreviateName,
  getInitialDeviceId,
  profileImageSrc,
} from '@/helpers';
import {
  useNavStore,
} from '@/store/nav';
import {
  ILoginType,
  IUser,
} from '@/types';

interface ILoginStorageData {
  token: string;
  loginType: ILoginType;
  deviceId: string;
}

interface ISetAuthInfoParams extends ILoginStorageData {
  user?: IUser;
}

async function getLoggedInUser(token: string): Promise<IUser | undefined> {
  try {
    const resp = await axios.get('/users/this', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data.user;
  } catch (e: any) {
    console.error(e);
    return undefined;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: '',
    loginType: '' satisfies ILoginType,
    user: undefined as IUser | undefined,
    deviceId: getInitialDeviceId(),
  }),
  getters: {
    usernameAbbreviation: (state) => abbreviateName(state.user),
    userProfileImageSrc: async (state) => {
      const profileImage = await profileImageSrc(state.user);
      return profileImage;
    },
  },
  actions: {
    async refreshUser() {
      const user = await getLoggedInUser(this.token);
      this.user = user;
    },
    setDeviceId(deviceId: string) {
      this.deviceId = deviceId;
    },
    setAuthInfo({ loginType, token, user, deviceId }: ISetAuthInfoParams) {
      this.loginType = loginType;
      this.user = user;
      this.token = token;

      if (deviceId !== undefined) {
        this.deviceId = deviceId;
      }

      const storage = window.localStorage.getItem('TABBED_REMEMBER_ME')
        ? window.sessionStorage : window.localStorage;

      const loginStorageData: ILoginStorageData = {
        loginType,
        token,
        deviceId: this.deviceId,
      };

      storage.setItem(
        CONSTANTS.STORAGE.LOGIN_DATA,
        JSON.stringify(loginStorageData),
      );
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    async login(loginType: ILoginType, token: string, deviceId: string) {
      const user = await getLoggedInUser(token);
      this.setAuthInfo({
        loginType, token, user, deviceId,
      });
    },
    async localLogin() {
      const storage = window.localStorage.getItem('TABBED_REMEMBER_ME')
        ? window.sessionStorage : window.localStorage;

      const stringifiedLoginData = storage.getItem(CONSTANTS.STORAGE.LOGIN_DATA);

      if (!stringifiedLoginData) {
        return;
      }

      const { token, loginType, deviceId } = JSON.parse(stringifiedLoginData) as ILoginStorageData;

      if (!token) {
        return;
      }

      const user = await getLoggedInUser(token);
      this.setAuthInfo({
        loginType: loginType as ILoginType, token, user, deviceId,
      });
    },

    logout() {
      useNavStore().isDashboardSidebarOpen = false;
      const { deviceId } = this;
      this.setAuthInfo({
        loginType: '', token: '', deviceId,
      });
      this.setDeviceId(deviceId);
    },
  },
  persist: {
    paths: ['deviceId'],
    storage: window.sessionStorage,
  },
});
