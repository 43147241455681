/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 26 2023
 */

<template>
  <CommonHeader
    v-if="!authStore.user"
  />
</template>

<script lang="ts" setup>

import {
  useRoute,
} from 'vue-router';
import CommonHeader from '@/components/NavBar/CommonHeader.vue';
import {
  useStore,
} from '@/store';

const authStore = useStore.auth;

const route = useRoute();

</script>
