/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import {
  useAppStore,
} from '@/store/app';
import {
  useAuthStore,
} from '@/store/auth';
import {
  useMriPMAStore,
} from '@/store/mriPMA';
import {
  useNavStore,
} from '@/store/nav';
import {
  useUtilStore,
} from '@/store/util';

export interface IAppUseStore {
  app: ReturnType<typeof useAppStore>;
  auth: ReturnType<typeof useAuthStore>;
  nav: ReturnType<typeof useNavStore>;
  mriPMA: ReturnType<typeof useMriPMAStore>;
  util: ReturnType<typeof useUtilStore>;
}

const useStore = {
} as IAppUseStore;

const registerStore = (): void => {
  useStore.app = useAppStore();
  useStore.nav = useNavStore();
  useStore.util = useUtilStore();
  useStore.mriPMA = useMriPMAStore();

  // user specific store
  useStore.auth = useAuthStore();
};

const resetUserSpecificStore = (): void => {
  useAuthStore().$reset();
};

const resetStore = (): void => {
  resetUserSpecificStore();

  useAppStore().$reset();
  useMriPMAStore().$reset();
  useNavStore().$reset();
};

export {
  resetUserSpecificStore,
  registerStore,
  resetStore,
  useStore,
};

// NOTE: For hot module replacement see https://pinia.vuejs.org/cookbook/hot-module-replacement.html
