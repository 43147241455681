import {
  defineStore,
} from 'pinia';
import {
  useUtilStore,
} from '@/store/util';

export const useNavStore = defineStore('nav', {
  state: () => ({
    isLightTheme: true,
    isDashboardSidebarOpen: true,
  }),

  actions: {
    toggleDashboardSidebar() {
      this.isDashboardSidebarOpen = !this.isDashboardSidebarOpen;
    },
    toggleTheme() {
      this.isLightTheme = !this.isLightTheme;
      useUtilStore().theme = this.isLightTheme ? 'light' : 'dark';
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
