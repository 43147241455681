<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - July 08, 2022
 -->

<template>
  <PropNotification
    v-if="showNotification"
    :notification="notification"
    :notificationType="notificationType"
    :anchorText="anchorText"
    :anchorLink="anchorLink"
    @close="showNotification = false"
  />
</template>

<script lang="ts" setup>

import {
  onBeforeMount, ref,
} from 'vue';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import PropNotification from '@/components/App/PropNotification.vue';

const notification = ref('');
const notificationType = ref('');
const anchorLink = ref();
const anchorText = ref();
const showNotification = ref(false);

function _hideNonPermanentNotification(isPermanent?: boolean): void {
  if (isPermanent) {
    return;
  }
  window.setTimeout(() => {
    showNotification.value = false;
  }, 5000);
}

function _handleSuccessNotification(): void {
  Bus.on(NOTIFICATION.SUCCESS, (e) => {
    notification.value = e.message;
    anchorLink.value = e.anchorLink;
    anchorText.value = e.anchorText;
    notificationType.value = NOTIFICATION.SUCCESS;
    showNotification.value = true;
    _hideNonPermanentNotification(e.permanent);
  });
}

function _handleErrorNotification(): void {
  Bus.on(NOTIFICATION.ERROR, (e) => {
    notification.value = e.message;
    anchorLink.value = e.anchorLink;
    anchorText.value = e.anchorText;
    notificationType.value = NOTIFICATION.ERROR;
    showNotification.value = true;
    _hideNonPermanentNotification(e.permanent);
  });
}

function _handleInfoNotification(): void {
  Bus.on(NOTIFICATION.INFO, (e) => {
    notification.value = e.message;
    anchorLink.value = e.anchorLink;
    anchorText.value = e.anchorText;
    notificationType.value = NOTIFICATION.INFO;
    showNotification.value = true;
    _hideNonPermanentNotification(e.permanent);
  });
}

onBeforeMount(() => {
  _handleSuccessNotification();
  _handleErrorNotification();
  _handleInfoNotification();
});

</script>
