<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - December 08, 2021
 -->

<template>
  <div
    class="relative min-h-screen flex-col overflow-x-hidden"
  >
    <template v-if="utilStore.hasUpdate && utilStore.isForceUpdate && utilStore.autoUpdatable">
      <div class="fixed left-0 top-0 z-20 h-screen w-screen bg-purple-300/0">
        <UpdateModal />
      </div>
    </template>
    <AppHeader
      v-if="showDefaultHeader"
    />

    <main
      class="bg-transparent"
      :class="{
        'flex flex-row': authStore.user && navStore.isDashboardSidebarOpen,
      }"
    >
      <SideNav v-if="authStore.user && navStore.isDashboardSidebarOpen" class="fixed z-10" />
      <BusNotification />
      <div
        :class="{
          'ml-60 w-full md:ml-64': authStore.user && navStore.isDashboardSidebarOpen,
        }"
      >
        <RouterView />
      </div>
    </main>
    <AppFooter
      v-if="!authStore.user"
      class="lg:transition-all lg:duration-500 lg:ease-in-out"
      :class="{
        'lg:ml-48': isHistoryListOpen && route.fullPath.startsWith(CONSTANTS.ROUTE_PREFIX.DASHBOARD),
        'lg:ml-0': !isHistoryListOpen && route.fullPath.startsWith(CONSTANTS.ROUTE_PREFIX.DASHBOARD),
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import {
  useRoute,
} from 'vue-router';
import Bus, {
  NOTIFICATION,
} from './bus';
import {
  CONSTANTS,
  identifyDeviceType,
  viewportBreakpoint,
} from './helpers';
import AppFooter from '@/components/App/AppFooter.vue';
import BusNotification from '@/components/App/BusNotification.vue';
import UpdateModal from '@/components/Helper/UpdateModal.vue';
import AppHeader from '@/components/NavBar/AppHeader.vue';
import SideNav from '@/components/NavBar/SideNav.vue';
import {
  useStore,
} from '@/store';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

interface IPlatformInfo {
  platform: string;
  arch: string;
}

let storageEventHandler: (e: StorageEvent) => void;
let resizedBreakpointEventHandler: (e: Event) => void;
let themeChangeEventListener: (e: Event) => void;

const route = useRoute();
const appStore = useStore.app;
const navStore = useStore.nav;
const utilStore = useStore.util;
const authStore = useStore.auth;

const isHistoryListOpen = computed(() => navStore.isDashboardSidebarOpen);

const showDefaultHeader = computed(() => {
  const { fullPath, name } = route;
  return !fullPath.startsWith('/auth')
  && name !== CONSTANTS.TITLES.HOME_ROUTE;
});

const storeDeviceId = (deviceId: string): void => {
  try {
    authStore.setDeviceId(deviceId);
    localStorage.setItem('deviceId', deviceId);
    console.log('Device ID stored successfully in authStore.');
  } catch (e) {
    console.error('Failed to store Device ID in authStore.', e);
  }
};

const storePlatformInfo = (data: IPlatformInfo): void => {
  try {
    utilStore.setPlatformInfo(data);
  } catch (e) {
    console.error('Failed to store Platform information in utilStore.', e);
  }
};

onBeforeMount(async () => {
  const lightThemeMQ = window.matchMedia('(prefers-color-scheme: light)');

  window.electron.receiveDeviceId((receivedDeviceId) => {
    storeDeviceId(receivedDeviceId);
  });

  window.electron.getAppPlatform((platformInfo: IPlatformInfo) => {
    storePlatformInfo(platformInfo);
  });

  window.electron.getAppVersion((version: string) => {
    utilStore.version = version;
  });

  await utilStore.checkForUpdate();
  await utilStore.setDownloadLink();

  if (utilStore.hasUpdate && !utilStore.isForceUpdate) {
    Bus.emit(NOTIFICATION.INFO, {
      message: 'Update is available. Please download and install the update.',
    });
  }

  // if (!utilStore.theme) {
  //   navStore.isLightTheme = lightThemeMQ.matches;
  //   lightThemeMQ.addEventListener('change', (event) => {
  //     const lightColorScheme = event.matches;
  //     navStore.isLightTheme = lightColorScheme;
  //   });
  // } else {
  //   navStore.isLightTheme = utilStore.theme === 'light';
  // }

  storageEventHandler = (e: StorageEvent): void => {
    if (e.key === 'token') {
      // local storage token changed
      console.log(`Key Changed: ${e.key}`);
      window.sessionStorage.removeItem('token');
      window.location.reload();
    }
  };
  resizedBreakpointEventHandler = (e: Event): void => {
    const device = identifyDeviceType();
    appStore.breakpoint = viewportBreakpoint();
    appStore.device = device;
  };

  window.addEventListener('resize', resizedBreakpointEventHandler);
  window.addEventListener('storage', storageEventHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('storage', storageEventHandler);
  window.removeEventListener('resize', resizedBreakpointEventHandler);
  window.removeEventListener('change', themeChangeEventListener);
});
</script>
