export const CONSTANTS = {
  LOGS: {
    INFO: 'log-info',
  } as const,
  ROUTES: {
    GUEST_HOME: '/',
    MEMBER_HOME: '/dashboard',
    ACCOUNT_PAGE: '/user/account',
  } as const,
  TITLES: {
    HOME_ROUTE: 'Home',
  } as const,
  ROUTE_PREFIX: {
    DASHBOARD: '/dashboard',
  },
  STORAGE: {
    LOGIN_DATA: 'LOGIN_DATA',
  } as const,
} as const;
