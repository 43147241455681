/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 26 2023
 */

<template>
  <button
    type="button"
    class="mx-5 my-auto h-fit w-fit rounded-md bg-blue-primary px-3 py-2 text-sm text-white
          shadow-sm shadow-blue-400 hover:bg-blue-400 hover:shadow-none sm:text-base sm:font-semibold"
    @click="router.push('/auth/login')"
  >
    Sign in
  </button>
</template>

<script setup lang="ts">

import {
  useRouter,
} from 'vue-router';

const router = useRouter();

</script>
