import axios from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  ISettings,
} from 'types';
import {
  downloadLinks,
} from '@/configs/client.config.json';

interface IPlatformInfo {
  platform: string;
  arch: string;
}

export const useUtilStore = defineStore('util', {
  state: () => ({
    theme: '',
    version: '',
    minVersion: '',
    platform: '',
    arch: '',
    downloadLink: '',
    updateFile: '',
    hasUpdate: false,
    isForceUpdate: false,
    autoUpdatable: false,
  }),
  actions: {
    setPlatformInfo(data: IPlatformInfo) {
      this.platform = data.platform;
      this.arch = data.arch;
    },

    setManualDownloadLink() {
      const isGlobal = this.version.startsWith('Global');

      if (this.platform === 'win32') {
        this.downloadLink = isGlobal ? downloadLinks.globalWindows : downloadLinks.euWindows;
        return;
      }

      if (this.platform === 'darwin' && this.arch === 'arm64') {
        this.downloadLink = isGlobal ? downloadLinks.globalMacSilicon : downloadLinks.euMacSilicon;
        return;
      }

      if (this.platform === 'darwin' && this.arch === 'x64') {
        this.downloadLink = isGlobal ? downloadLinks.globalMacIntel : downloadLinks.euMacIntel;
        return;
      }

      if (this.platform === 'linux') {
        this.downloadLink = isGlobal ? downloadLinks.globalLinux : downloadLinks.euLinux;
      }
    },

    async setAutoUpdateLink() {
      if (!this.platform) {
        return;
      }

      let url = `/settings/update?platform=${this.platform}`;

      if (this.arch) {
        url += `&arch=${this.arch}`;
      }

      const res = await axios.get<{
        success: boolean;
        update_file: string;
      }>(url);

      if (res.data.success && res.data.update_file) {
        this.updateFile = res.data.update_file;
      }
    },

    async setDownloadLink() {
      if (this.autoUpdatable) {
        await this.setAutoUpdateLink();
        return;
      }

      this.setManualDownloadLink();
    },

    sanitizeVersion(data: string) {
      const sanitizedVersion = data.startsWith('Global') ? data.split(' ').pop() : data;
      return sanitizedVersion;
    },

    isVersionAboveMinimum(current: string, minimum: string) {
      if (current === minimum) {
        return true;
      }

      const currentParts = current.split('.').map(Number);
      const minimumParts = minimum.split('.').map(Number);

      for (let i = 0; i < Math.max(currentParts.length, minimumParts.length); i++) {
        const currentPart = currentParts[i] || 0;
        const minimumPart = minimumParts[i] || 0;

        if (currentPart < minimumPart) {
          return false;
        }
        if (currentPart > minimumPart) {
          return true;
        }
      }

      return false;
    },

    isEligibleForAutoUpdate(minVersion: string) {
      const current = this.sanitizeVersion(this.version);
      const minimum = this.sanitizeVersion(minVersion);

      this.autoUpdatable = !!current && !!minimum && this.isVersionAboveMinimum(current, minimum);
    },

    async checkForUpdate() {
      try {
        const res = await axios.get<{ settings: ISettings }>('/settings/');
        if (res.data.settings.current_version !== this.version) {
          this.hasUpdate = true;
          this.minVersion = res.data.settings.minimum_version;
          this.isEligibleForAutoUpdate(this.minVersion);
          this.isForceUpdate = res.data.settings.force_update;
        } else {
          this.hasUpdate = false;
          this.isForceUpdate = false;
        }
      } catch (error: any) {
        console.error(error);
      }
    },
  },
  persist: {
    storage: localStorage,
  },
});
