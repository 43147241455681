import axios, {
  AxiosError,
} from 'axios';
import {
  getAppInfo,
} from 'utilities';
import {
  CONSTANTS,
} from './constants';

import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  baseServerUrl,
} from '@/configs/client.config.json';
import {
  IDevice,
  ILoginType,
  ISearchParams,
  IUser,
} from '@/types';

export * from './constants';

interface ILoginStorageData {
  token: string;
  loginType: ILoginType;
  deviceId: string;
}

interface ISetAuthInfoParams extends ILoginStorageData {
  user?: IUser;
}

export const {
  SERVER_PORT,
  CLIENT_PORT,
  IS_DEVELOPMENT,
  CONFIG,
  ROUTE_API,
  VIEW_API,
} = getAppInfo();

const { protocol, hostname } = window.location;

// for local testing, baseServerUrl = https://localhost:3001 can be set, if necessary
export const baseUrl = baseServerUrl || (IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${SERVER_PORT}`
  : window.location.origin);

export const clientBaseUrl = IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${CLIENT_PORT}`
  : window.location.origin;

export function isYouTubeUrl(url: string): boolean {
  return /https?:\/\/(www\.)?youtu\.?be/.test(url);
}

export const isUUIDv4 = (inputString: string): boolean => {
  const uuidv4Pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidv4Pattern.test(inputString);
};

export const sleep = (ms: number): Promise<unknown> => new Promise((r) => {
  setTimeout(r, ms);
});

export function ordinalize(n: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function abbreviateName(user?: IUser): string {
  if (!user) {
    return '';
  }
  if (user.firstName && user.lastName) {
    return (user.firstName[0] + user.lastName[0]).toUpperCase();
  }
  const userName = user?.firstName || user?.username || '';
  return userName.slice(0, 2).toUpperCase();
}

export function viewportBreakpoint(): string {
  const width = window.innerWidth;
  let breakpoint = 'xs';

  if (width >= 640) {
    breakpoint = 'sm';
  }
  if (width >= 768) {
    breakpoint = 'md';
  }
  if (width >= 1024) {
    breakpoint = 'lg';
  }
  if (width >= 1280) {
    breakpoint = 'xl';
  }
  if (width >= 1536) {
    breakpoint = '2xl';
  }
  return breakpoint;
}

export async function profileImageSrc(user?: IUser): Promise<string> {
  // if there is no profile image
  if (!user?.profileImage) {
    return '';
  }

  const { profileImage } = user;

  // if profileImage is stored in server
  if (!profileImage.startsWith('http')) {
    return `${baseUrl}${ROUTE_API}/files/${profileImage}?local=1`;
  }

  return profileImage;
}

export async function getStorageFileSrc(url: string): Promise<string> {
  const sanitizationPrefix = `${baseUrl}${ROUTE_API}`;
  if (!url.startsWith(sanitizationPrefix)) {
    return url;
  }
  const route = url.replace(sanitizationPrefix, '');
  const response = await axios.get(`${route}?url=1`);
  return response.data.url;
}

export function durationTime(val: number): string {
  if (!val) {
    return '';
  }
  return val >= 3600
    ? new Date(val * 1000).toISOString().substr(11, 8)
    : new Date(val * 1000).toISOString().substr(14, 5);
}

export function handleAxiosError<T extends {
  message: string;
  errors?: Map<string, string> | Record<string, string | undefined>;
}>(e: AxiosError<T>, showAdditionalError = false): void {
  console.error(e);

  if (e.isAxiosError && e.response?.data) {
    let errorMessages = '';
    let message = e.response.statusText;
    if (typeof e.response.data === 'string') {
      message = e.response.data;
    } else if (e.response.data.message) {
      message = e.response.data.message;
    }

    if (e.response.data.errors && showAdditionalError) {
      const { errors } = e.response.data;
      for (const key of Object.keys(errors)) {
        errorMessages += `, ${key}: `;
        if (errors instanceof Map) {
          errorMessages += errors.get(key);
        } else {
          errorMessages += errors[key];
        }
      }
    }

    Bus.emit(NOTIFICATION.ERROR, {
      message: message + errorMessages,
      permanent: true,
    });
  }
}

export function identifyDeviceType(): IDevice {
  let isMobile = false;
  if (
    /Android/i.test(navigator.userAgent)
    && /Mobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  } else if (/webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  } else if (window.innerWidth < 768) {
    isMobile = true;
  }

  const isTouchDevice = 'ontouchend' in document;
  const isChrome = /Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent);
  const isSafari = /Safari/i.test(navigator.userAgent) && !isChrome;
  const isFirefox = /Firefox/i.test(navigator.userAgent);
  const isMacintosh = /Macintosh/i.test(navigator.userAgent);
  const isMac = isMacintosh && !isTouchDevice;
  const isWindows = /Windows/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isAndroidTablet = /Android/i.test(navigator.userAgent)
    && !/Mobile/i.test(navigator.userAgent);
  const isLinux = /Linux/i.test(navigator.userAgent) && !isAndroid;
  const isIPad = /iPad/i.test(navigator.userAgent) || (isMacintosh && isTouchDevice);
  const isIPhone = /iPhone/i.test(navigator.userAgent);

  return {
    isMobile,
    isTouchDevice,
    isChrome,
    isSafari,
    isFirefox,
    isMac,
    isWindows,
    isAndroid,
    isLinux,
    isIPad,
    isIPhone,
    isAndroidTablet,
  };
}

export function download(content: string | Blob, filename: string): void {
  let href;

  if (content instanceof Blob) {
    href = window.URL.createObjectURL(content);
  } else {
    href = `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`;
  }

  const element = document.createElement('a');
  element.setAttribute('href', href);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  // If the content is a blob, revoke the object URL to free up memory
  if (content instanceof Blob) {
    window.URL.revokeObjectURL(href);
  }

  document.body.removeChild(element);
}

export async function copyToClipboard(text: string): Promise<void> {
  const _legacyCopy = (): void => {
    const input: HTMLInputElement = window.document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.focus();
    input.select();

    document.execCommand('copy');
    document.body.removeChild(input);
  };

  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    _legacyCopy();
  }
}

export function getSearchParamsFromUrl(
  url: string,
): ISearchParams {
  const { searchParams } = new URL(decodeURI(url), window.location.origin);

  const reference = searchParams.get('reference') || undefined;
  const redirect = searchParams.get('redirect') || undefined;

  return {
    reference, redirect,
  };
}

export async function redirectUserAfterLogin(
  user: IUser,
  redirectUrl = '',
): Promise<{ route: string }> {
  const handleFirstLoginRedirect = (): { route: string } => {
    let route = CONSTANTS.ROUTES.ACCOUNT_PAGE;
    if (redirectUrl) {
      route += `?redirect=${encodeURIComponent(redirectUrl)}`;
    }
    return {
      route,
    };
  };

  if (user.isFirstLogin) {
    return handleFirstLoginRedirect();
  }

  // IF it is end-user BUT
  // there is no `redirectUrl` OR `redirectUrl` is not an end-user url
  // THEN route to appropriate end-user url
  if (!redirectUrl) {
    return {
      route: CONSTANTS.ROUTES.MEMBER_HOME,
    };
  }

  // otherwise go to redirect url
  return {
    route: redirectUrl,
  };
}

export function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);

  const base64 = (base64String + padding)
    // eslint-disable-next-line
    .replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function getInitialDeviceId(): string {
  const storage = window.localStorage.getItem('TABBED_REMEMBER_ME') ? window.sessionStorage : window.localStorage;
  const stringifiedLoginData = storage.getItem(CONSTANTS.STORAGE.LOGIN_DATA);
  if (stringifiedLoginData) {
    const loginData = JSON.parse(stringifiedLoginData) as ILoginStorageData;
    return loginData.deviceId || '';
  }
  return '';
}
