import axios from 'axios';
import {
  defineStore,
} from 'pinia';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  ILevelsReport,
  IPatientInfoReport,
} from '@/types';

export const useMriPMAStore = defineStore('mriPMA', {
  state: () => ({
    patientInfo: undefined as IPatientInfoReport | undefined,
    levels: undefined as ILevelsReport | undefined,

    isLoading: false,
    isPatientInfoLoading: false,
    isReportPage: false,
    isSaved: false,

    // MRI file, will be used in download from report page.
    mriFile: '',
    // For error modal data
    errorMessage: '',
    // For warning modal data
    warningMessage: '',
    stages: [
      {
        stage: 'Information Extracted', status: 'PENDING',
      },
      {
        stage: 'Series Analyzed', status: 'PENDING',
      },
      {
        stage: 'Quality Check', status: 'PENDING',
      },
      {
        stage: 'Slice Selection', status: 'PENDING',
      },
      {
        stage: 'Segmentation', status: 'PENDING',
      },
      {
        stage: 'Metrics Calculation', status: 'PENDING',
      },
    ],
  }),
  actions: {
    updateStepStatus(step: string, status: string) {
      const stage = this.stages.find((s) => s.stage === step);
      if (stage) {
        stage.status = status.toUpperCase();
      }
    },

    async saveResult(): Promise<void> {
      if (!this.levels || !this.patientInfo) {
        Bus.emit(NOTIFICATION.ERROR, {
          message: 'No analysis data available to save.',
        });
        return;
      }

      const levelsData = Object.entries(this.levels).map(([levelName, levelData]) => ({
        level_name: levelName,
        level_id: levelData.mri_analysis_info.level_id,
        mri_analysis_id: levelData.mri_analysis_id,
        muscle_analysis: levelData.metrics,
      }));

      const payload = {
        patient_id: this.patientInfo.id,
        levels_data: levelsData,
      };

      try {
        console.log('its going for save', payload);
        const response = await axios.post<{
          message: string;
          success: boolean;
        }>('/muscle-analysis/save', payload);

        if (response.data.success) {
          this.isSaved = true;
          Bus.emit(NOTIFICATION.SUCCESS, {
            message: response.data.message,
          });
        } else {
          Bus.emit(NOTIFICATION.ERROR, {
            message: response.data.message || 'Failed to save muscle analysis data.',
          });
        }
      } catch (error) {
        console.error('Error saving muscle analysis data:', error);
        Bus.emit(NOTIFICATION.ERROR, {
          message: 'An error occurred while saving the data.',
        });
      }
    },

    resetStages() {
      this.stages = this.stages.map((stage) => ({
        ...stage, status: 'PENDING',
      }));
    },

    resetErrorAndWarning() {
      this.warningMessage = '';
      this.errorMessage = '';
    },

    reset() {
      this.isReportPage = false;
      this.isSaved = false;
      this.patientInfo = undefined;
      this.levels = undefined;
      this.mriFile = '';
      this.resetStages();
      this.resetErrorAndWarning();
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
