/**
 * Ragib Un Nur
 * ragib@nerddevs.com
 * Created on Fri Jul 08 2022
 */

<template>
  <RouterLink
    class="rounded-lg p-3 text-left text-base font-normal no-underline hover:no-underline active:no-underline"
    :class="{
      'bg-base-300 font-bold': name === router.currentRoute.value.name,
      'hover:bg-blue-primary hover:text-white': navStore.isLightTheme,
      'hover:bg-gray-100 hover:text-black': !navStore.isLightTheme,
    }"
    :target="url ? '_blank' : undefined"
    rel="noopener noreferrer"
    :href="url"
    :to="route"
    @click="$emit(event || 'click')"
  >
    {{ name }}
  </RouterLink>
</template>

<script lang="ts" setup>
import {
  useRouter,
} from 'vue-router';
import {
  useStore,
} from '@/store';

const navStore = useStore.nav;

export interface IHeaderTextLink {
  name: string;
  route?: string;
  url?: string;
  event?: string;
  userOnly?: boolean;
  hidden?: boolean;
  isMobile?: boolean;
}

defineProps({
  name: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    required: false,
    default: '',
  },
  url: {
    type: String,
    required: false,
    default: '',
  },
  block: {
    type: Boolean,
    required: false,
  },
  event: {
    type: String,
    default: '',
  },
});

const router = useRouter();
</script>
