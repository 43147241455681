/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 26 2023
 */

<template>
  <nav class="flex h-20 justify-between">
    <NavLogoWithText />

    <div class="flex items-center">
      <!-- <ToggleTheme /> -->
      <MemberMenu
        v-if="user"
      />
      <NavSignInButton
        v-else
      />
    </div>
  </nav>
</template>

<script lang="ts" setup>
import {
  computed,
  ref,
  watch,
} from 'vue';
import MemberMenu from '@/components/NavBar/MemberMenu.vue';
import NavLogoWithText from '@/components/NavBar/NavLogoWithText.vue';
import NavSignInButton from '@/components/NavBar/NavSignInButton.vue';
import ToggleTheme from '@/components/NavBar/ToggleTheme.vue';
import {
  useStore,
} from '@/store';

const navStore = useStore.nav;

const toggleLight = ref(false);

watch(() => toggleLight.value, () => {
  navStore.toggleTheme();
});

const authStore = useStore.auth;

const user = computed(() => authStore.user);

</script>
