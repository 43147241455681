import mitt from 'mitt';

// eslint-disable-next-line no-shadow
export enum NOTIFICATION {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

interface INotificationEvent {
  message: string;
  showNotification?: boolean;
  permanent?: boolean;
  anchorText?: string;
  anchorLink?: string;
}

type Events = Record<NOTIFICATION, INotificationEvent>;

const Bus = mitt<Events>();

export default Bus;
