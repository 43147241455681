<template>
  <div class="flex h-screen w-60 flex-col border-r-4 border-[#00A3DE] md:w-64">
    <ConfirmationModal
      v-if="isConfirming && route.path === '/mri-analysis'"
      confirmationText="You are about to leave this page, do you want to save the result now?"
      cancelButtonText="No"
      confirmButtonText="Yes"
      :isLoading="isLoading"
      @confirm="onConfirm"
      @cancel="onLeave"
    >
      <template #confirmIcon>
        <PhFloppyDisk size="32" />
      </template>
    </ConfirmationModal>

    <button
      class="mt-6"
      type="button"
      :disabled="disabled"
      @click="navigate(CONSTANTS.ROUTES.MEMBER_HOME)"
    >
      <img
        :src="require('@/assets/site-assets/tissue-connect-logo.png')"
        alt="tissue-connect-logo"
        class="w-60"
      >
    </button>

    <div class="flex grow flex-col justify-between">
      <!-- Menus -->
      <div class="px-4">
        <div
          v-for="navMenu in navMenus"
          :key="navMenu.name"
        >
          <button
            v-if="navMenu.show"
            type="button"
            class="my-1 flex w-full items-center gap-3 rounded-lg p-3 text-start hover:bg-[#EFF6FF]"
            :class="route.path === navMenu.route ? 'bg-[#EFF6FF] text-[#2D68FE]' : ' text-[#324054]'"
            :disabled="disabled"
            @click="navigate(navMenu.route)"
          >
            <img :src="navMenu.icon" alt="icon">
            <div>
              {{ navMenu.name }}
            </div>
          </button>
        </div>
      </div>

      <div>
        <div
          class="px-4"
          :class="{ 'mb-4': utilStore.hasUpdate }"
        >
          <div
            v-for="nav in userControlledNavs"
            :key="nav.name"
          >
            <button
              v-if="nav.show"
              type="button"
              :disabled="disabled"
              class="flex w-full items-center gap-3 rounded-lg p-3 hover:bg-[#EFF6FF]"
            >
              <img :src="nav.icon" alt="icon">
              <div
                class="text-[#324054]"
              >
                {{ nav.name }}
              </div>
            </button>
          </div>

          <button
            type="button"
            class="flex w-full items-center gap-3 rounded-lg p-3 hover:bg-[#EFF6FF]"
            :class="{ 'mb-4': utilStore.hasUpdate }"
            :disabled="disabled"
            @click="logout"
          >
            <img :src="LogOutIcon" alt="icon">
            <div
              class="text-[#324054]"
            >
              Logout
            </div>
          </button>

          <template v-if="utilStore.hasUpdate && !utilStore.isForceUpdate">
            <UpdateHelper v-if="utilStore.autoUpdatable" />
            <span v-else>
              <div
                class="tooltip tooltip-right w-full"
                data-tip="Download and Install the update"
              >
                <a
                  class="flex items-center gap-3 rounded-lg bg-[#00A1E0] p-3 text-white shadow-lg shadow-blue-300
                  duration-300 hover:bg-[#00A1E0]/80 hover:shadow-md hover:shadow-blue-300 hover:transition-all"
                  :href="utilStore.downloadLink"
                >
                  <ArrowDownTrayIcon class="size-5 stroke-2" />
                  Download Update
                </a>
              </div>
            </span>
          </template>
        </div>

        <div class="flex flex-col p-3 pl-6">
          <!-- TODO: add user name (first name and last name) -->
          <p class="text-[#2D68FE]">
            {{ authStore.user?.username }}
          </p>
          <p class="my-2 text-[#324054]">
            <span class="font-medium text-[#324054]">Version:</span> {{ appVersion }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ArrowDownTrayIcon,
} from '@heroicons/vue/24/outline';
import {
  PhFloppyDisk,
} from '@phosphor-icons/vue';
import {
  computed,
  ref,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import HistoryIcon from '@/assets/icons/History.svg';
import HomeIcon from '@/assets/icons/Home.svg';
import LogOutIcon from '@/assets/icons/Logout.svg';
import MRIanalysisToolIcon from '@/assets/icons/MRI_analysis_tool.svg';
import ParaSpinalMuscleParametersIcons from '@/assets/icons/Paraspinal_Muscle_Parameters.svg';
import SegmentationIcon from '@/assets/icons/Segmentation.svg';
import SettingIcon from '@/assets/icons/Settings.svg';
import SliceSelectionIcon from '@/assets/icons/Slice_Selection.svg';
import ConfirmationModal from '@/components/Helper/ConfirmationModal.vue';
import UpdateHelper from '@/components/Helper/UpdateHelper.vue';
import {
  version,
} from '@/configs/client.config.json';
import {
  CONSTANTS,
} from '@/helpers';
import {
  resetUserSpecificStore,
  useStore,
} from '@/store';

const authStore = useStore.auth;
const router = useRouter();
const route = useRoute();

const utilStore = useStore.util;
const mriPMAStore = useStore.mriPMA;

const isConfirming = ref(false);
const isLoading = ref(false);

const clickedRoute = ref<string | undefined>();

const appVersion = computed(() => utilStore.version || version);

const disabled = computed(() => mriPMAStore.isLoading || mriPMAStore.isPatientInfoLoading);

const navMenus = [
  {
    name: 'Home',
    icon: HomeIcon,
    route: '/',
    show: true,
  },
  {
    name: 'MRI Lumbar Connect',
    icon: MRIanalysisToolIcon,
    route: '/mri-analysis',
    show: true,
  },
  {
    name: 'Slice Selection',
    icon: SliceSelectionIcon,
    show: false,
  },
  {
    name: 'Segmentation',
    icon: SegmentationIcon,
    show: false,
  },
  {
    name: 'Paraspinal Muscle Parameters',
    icon: ParaSpinalMuscleParametersIcons,
    show: false,
  },
  {
    name: 'History',
    icon: HistoryIcon,
    route: '/history',
    show: true,
  },
];

const userControlledNavs = [
  {
    name: 'Settings',
    icon: SettingIcon,
    show: true,
  },
];

async function logoutAndRedirect(): Promise<void> {
  await authStore.logout();
  resetUserSpecificStore();
  router.push('/');
}

async function logout(): Promise<void> {
  // NOTE: If there's any mri processing going on, logout should be locked.
  if (disabled.value) {
    return;
  }

  clickedRoute.value = 'logout';

  if (mriPMAStore.isReportPage && !mriPMAStore.isSaved) {
    isConfirming.value = true;
    return;
  }

  await logoutAndRedirect();
}

async function onConfirm(): Promise<void> {
  isLoading.value = true;
  try {
    await mriPMAStore.saveResult();

    if (!clickedRoute.value) {
      return;
    }

    mriPMAStore.reset();

    if (clickedRoute.value === 'logout') {
      await logoutAndRedirect();
      return;
    }

    await router.push(clickedRoute.value);
  } catch (e: any) {
    console.error(e);
  } finally {
    isLoading.value = false;
    isConfirming.value = false;
  }
}

async function onLeave(): Promise<void> {
  if (!clickedRoute.value) {
    return;
  }

  isConfirming.value = false;

  mriPMAStore.reset();

  if (clickedRoute.value === 'logout') {
    await logoutAndRedirect();
    return;
  }

  await router.push(clickedRoute.value);
}

async function navigate(navRoute?: string): Promise<void> {
  // NOTE: If there's any mri processing going on, sidebar should be locked.
  if (route.path === '/mri-analysis' && disabled.value) {
    return;
  }

  clickedRoute.value = navRoute;

  if (!clickedRoute.value) {
    return;
  }

  if (route.path === '/mri-analysis' && mriPMAStore.isReportPage && !mriPMAStore.isSaved) {
    isConfirming.value = true;
    return;
  }

  mriPMAStore.reset();

  await router.push(clickedRoute.value);
}
</script>
