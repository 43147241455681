/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 26 2023
 */

<template>
  <RouterLink
    :to="appStore.homeRoute"
    class="mx-5 my-auto"
  >
    <img
      :src="require('@/assets/site-assets/tissue-connect-logo.png')"
      alt="logo"
      class="h-16"
    >
  </RouterLink>
</template>

<script setup lang="ts">
import {
  useStore,
} from '@/store';

const appStore = useStore.app;
</script>
