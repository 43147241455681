/**
 * Ragib Un Nur
 * ragib@nerddevs.com
 * Created on Thu Jul 14 2022
 */

<template>
  <div class="dropdown relative my-auto ml-2 inline-block md:mx-5">
    <div
      class="flex items-center"
    >
      <div
        :data-tip="name"
        class="relative md:tooltip md:tooltip-success md:tooltip-left before:overflow-hidden"
      >
        <button
          type="button"
          class="h-11 w-11 rounded-full"
          @click="isMemberMenuOpen = !isMemberMenuOpen"
        >
          <ProfileImageAvatar
            v-if="user"
            :user="user"
            :size="44"
          />
        </button>
        <button
          v-if="isMemberMenuOpen"
          type="button"
          tabindex="-1"
          class="fixed inset-0 z-10 h-full w-full cursor-default bg-gray-500 opacity-20"
          @click="isMemberMenuOpen = false"
        />
        <div
          v-if="isMemberMenuOpen"
          class="absolute right-0 z-10 mt-2 rounded-lg bg-base-100 shadow-sm shadow-black md:min-w-[224px]"
        >
          <ul
            tabindex="0"
            class="menu rounded p-2 shadow shadow-gray-700"
          >
            <span
              v-if="user"
              class="flex p-3 lg:hidden"
            >
              <ProfileImageAvatar
                :user="user"
                :size="50"
              />
              <div
                class="text-normal ml-3 flex-col items-baseline truncate
                p-1 font-medium"
              >
                {{ name }}
                <p
                  class="truncate text-xs font-normal"
                >
                  {{ user.username }}
                </p>
              </div>
            </span>

            <HeaderTextLinkButton
              v-for="(item, index) in availableTextLinks"
              :key="`${item.name}_${index}`"
              :name="item.name"
              :route="item.route"
              :url="item.url"
              :event="item.event"
              block
            />
            <HeaderTextLinkButton
              name="Sign out"
              block
              @click="logout"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  startCase,
} from 'lodash';
import {
  IUser,
} from 'types';
import {
  applicationStoreUrl,
} from 'utilities';
import {
  computed,
  ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import ProfileImageAvatar from '@/components/Helper/ProfileImageAvatar.vue';
import HeaderTextLinkButton, {
  IHeaderTextLink,
} from '@/components/NavBar/HeaderTextLinkButton.vue';
import {
  resetUserSpecificStore,
  useStore,
} from '@/store';

const router = useRouter();
const authStore = useStore.auth;
const isMemberMenuOpen = ref(false);

const user = computed(() => authStore.user);

const name = computed(() => {
  if (!user.value) {
    return '';
  }
  return startCase(`${user.value.firstName} ${user.value.lastName || ''}`);
});

const MEMBER_TEXT_LINKS: IHeaderTextLink[] = [
  {
    name: 'Dashboard', route: '/',
  },
  {
    name: 'Account', route: '/user/account',
  },
  {
    name: 'Contact', route: '/contact',
  },
  {
    name: 'Privacy', route: '/privacy-policy',
  },
  {
    name: 'Terms', route: '/terms-of-service',
  },
  {
    name: 'FAQ', route: '/faq',
  },
];

const availableTextLinks = computed(() => MEMBER_TEXT_LINKS
  .filter((l) => {
    const mainCondition = !l.hidden && user;

    return mainCondition;
  }));

function logout(): void {
  resetUserSpecificStore();
  authStore.logout();

  router.push('/');
}

</script>
