<template>
  <footer
    class="absolute bottom-0 w-full"
  >
    <div class="p-2">
      <p class="text-center text-sm sm:text-base">
        Copyright © {{ currentYear }} {{ CONFIG.appName }}, all rights reserved
      </p>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import {
  CONFIG,
} from '@/helpers';

const currentYear = new Date().getFullYear();

</script>
