<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - July 13, 2022
 -->

<template>
  <div
    class="avatar"
    :class="{ placeholder: !profileImage }"
    tabindex="0"
    @click="$emit('click')"
    @keypress="$emit('click')"
  >
    <div
      class="rounded-full transition-all"
      :style="{ width: `${size}px`, height: `${size}px` }"
      :class="{
        'ring-1 ring-offset-1': bordered || !profileImage,
        'bg-transparent text-neutral-content': !profileImage,
        'ring-blue-primary ring-offset-blue-primary': navStore.isLightTheme,
        'ring-blue-100 ring-offset-blue-100': !navStore.isLightTheme,
      }"
    >
      <img
        v-if="profileImage"
        :src="profileImage"
        alt="profile-image"
      >
      <span
        v-else
        class="transition-all"
        :class="{
          'text-blue-primary': navStore.isLightTheme,
          'text-white': !navStore.isLightTheme,
        }"
        :style="{ fontSize: `${size / 2}px` }"
      >
        {{ abbreviatedName }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  PropType,
  computed,
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import SITE_LOGO from '@/assets/site-assets/logo.png';
import {
  abbreviateName,
  profileImageSrc,
} from '@/helpers';
import {
  useStore,
} from '@/store';
import {
  IUser,
} from '@/types';

const navStore = useStore.nav;
const authStore = useStore.auth;

defineEmits<{
  (e: 'click'): void;
}>();

const props = defineProps({
  user: {
    type: Object as PropType<IUser>,
    required: true,
  },
  size: {
    type: Number,
    default: 40,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
});

const profileImage = ref('');

const abbreviatedName = computed(() => abbreviateName(props.user));

async function _setProfileImage(): Promise<void> {
  if (!props.user) {
    return;
  }

  profileImage.value = await profileImageSrc(props.user);
}

watch(() => props.user, () => {
  _setProfileImage();
});

onBeforeMount(async () => {
  _setProfileImage();
});

</script>
