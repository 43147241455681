<template>
  <ConfirmationModal
    v-if="confirmUpdate && !utilStore.isForceUpdate"
    confirmationText="Update has been downloaded. Please restart the application to apply update."
    cancelButtonText="Later"
    confirmButtonText="Quit and install"
    :isLoading="isLoading"
    @confirm="onRestartConfirm"
    @cancel="onIgnoreUpdate"
  />
  <template v-if="!forceUpdate">
    <div v-if="updateDownloadProgression">
      Download Progress: {{ updateDownloadProgression }}%
    </div>

    <div
      v-if="isPreparingForUpdate || isUpdating"
    >
      <div class="w-full rounded-full bg-slate-200">
        <div
          class="progress-bar"
          :style="{ width: updateDownloadProgression + '%' }"
        >
          <span class="rounded-full" />
        </div>
      </div>
    </div>

    <div
      v-if="utilStore.hasUpdate && !isPreparingForUpdate && !isUpdating"
      class="tooltip tooltip-right w-full"
      data-tip="Download and Install the update"
    >
      <button
        type="button"
        :disabled="isUpdating"
        class="flex items-center gap-3 rounded-lg bg-[#00A1E0] p-3 text-white shadow-lg shadow-blue-300
              duration-300 hover:bg-[#00A1E0]/80 hover:shadow-md hover:shadow-blue-300 hover:transition-all"
        @click="downloadUpdate"
      >
        <span
          v-if="isUpdating"
          class="loading loading-spinner"
        />
        <ArrowDownTrayIcon
          v-else
          class="size-5 stroke-2"
        />
        Download Update
      </button>
    </div>
  </template>
  <template v-else>
    <div class="flex flex-col items-center justify-center gap-4 px-2 text-gray-700">
      <h1 class="text-xl font-medium">
        Please Update Tissue Connect
      </h1>
      <span>
        Update is required to use the application. Please download and install the update.
      </span>
    </div>

    <div class="flex h-[54px] w-full items-center justify-center">
      <button
        v-if="!isUpdating"
        type="button"
        :disabled="isUpdating"
        class="flex w-fit items-center gap-3 rounded-md bg-[#00A1E0] px-3 py-2 text-white shadow-lg
          shadow-blue-300 duration-300 hover:bg-[#00A1E0]/80 hover:shadow-md hover:shadow-blue-300 hover:transition-all"
        @click="downloadUpdate"
      >
        <span
          v-if="isUpdating"
          class="loading loading-spinner"
        />
        <ArrowDownTrayIcon
          v-else
          class="size-5 stroke-2"
        />
        Download and install Update
      </button>

      <div
        v-if="isPreparingForUpdate || isUpdating"
        class="w-full"
      >
        <div v-if="updateDownloadProgression" class="px-2">
          Download Progress: {{ updateDownloadProgression }}%
        </div>

        <div class="w-full rounded-full bg-slate-200">
          <div
            class="progress-bar w-full"
            :style="{ width: updateDownloadProgression + '%' }"
          >
            <span class="w-full rounded-full" />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import {
  ArrowDownTrayIcon,
} from '@heroicons/vue/24/outline';
import {
  onMounted,
  ref,
} from 'vue';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import ConfirmationModal from '@/components/Helper/ConfirmationModal.vue';
import {
  useStore,
} from '@/store';

interface IElectronFile {
  filename: string;
  path: string;
  fileSize: number;
  mimeType: string;
  url: string;
}

defineProps({
  forceUpdate: {
    type: Boolean,
    default: false,
  },
});

const utilStore = useStore.util;
const confirmUpdate = ref(false);
const isLoading = ref(false);

const isPreparingForUpdate = ref(false);
const isUpdating = ref(false);

const updateDownloadProgression = ref(0);

function onRestartConfirm(): void {
  confirmUpdate.value = false;
  window.electron.applyUpdate();
}

function onIgnoreUpdate(): void {
  confirmUpdate.value = false;
}

function downloadUpdate(): void {
  isUpdating.value = true;

  window.electron.downloadUpdate(utilStore.updateFile);
}

function electronEventHandler(): void {
  if (!window.electron) {
    return;
  }

  window.electron.getDownloadProgress((data: any) => {
    isUpdating.value = true;
    updateDownloadProgression.value = Number((data.percent * 100).toFixed(0));
  });

  window.electron.onUpdateDownloadComplete((file: IElectronFile) => {
    window.electron.installUpdate(file);
  });

  window.electron.onCheckingForUpdate(() => {
    isPreparingForUpdate.value = true;
    isUpdating.value = false;
  });

  window.electron.onUpdateAvailable(() => {
    isPreparingForUpdate.value = false;
    isUpdating.value = true;
  });

  window.electron.onUpdateNotAvailable(() => {
    isPreparingForUpdate.value = false;
    isUpdating.value = false;

    Bus.emit(NOTIFICATION.ERROR, {
      message: 'Failed to update. Please try again later.',
    });
  });

  window.electron.onUpdateError((info: any) => {
    isPreparingForUpdate.value = false;
    isUpdating.value = false;

    Bus.emit(NOTIFICATION.ERROR, {
      message: 'An error occurred while updating the app. Please try again.',
    });
  });

  window.electron.onUpdateDownloaded((info: any) => {
    isPreparingForUpdate.value = false;
    isUpdating.value = false;

    if (utilStore.hasUpdate && utilStore.isForceUpdate) {
      window.electron.applyUpdate();
    } else {
      confirmUpdate.value = true;
    }
  });
}

onMounted(() => {
  electronEventHandler();
});

</script>
